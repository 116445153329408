import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import AddIcon from '@mui/icons-material/Add';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import { Box, IconButton, Theme, Typography, useTheme } from '@mui/material';

const BUTTON_TYPES = {
  create: {
    text: 'Create',
    icon: <AddIcon />,
  },
  upload: {
    text: 'Upload',
    icon: <FileUploadIcon />,
  },
  export: {
    text: 'Export',
    icon: <FileDownloadIcon />,
  },
  downloadTemplate: {
    text: 'Download Template',
    icon: <FileDownloadIcon />,
  },
};

const useStyles = makeStyles()((theme: Theme) => ({
  buttonBox: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '14px 32px',
    borderRadius: '12px',
    color: theme.palette.common.white,
  },
  buttonIcon: {
    padding: 0,
    color: theme.palette.common.white,
    svg: {
      width: '20px',
      height: '20px',
    },
  },
  linkMargin: {
    textDecoration: 'none',
    // marginRight: '20px',

    '@media (max-width: 550px)': {
      marginRight: 0,
    },
  },
}));

interface ViewCommonButtonProps {
  path?: string;
  type: keyof typeof BUTTON_TYPES;
  onClick?: () => void;
}

const ViewCommonButton = ({ path, type, onClick }: ViewCommonButtonProps) => {
  const theme = useTheme();
  const styles = useStyles();

  const buttonProps: { to: string; onClick?: () => void } = {
    to: '',
  };

  if (path) {
    buttonProps.to = path;
  }
  if (onClick) {
    buttonProps.onClick = onClick;
  }

  return (
    <Link {...buttonProps} className={styles.classes.linkMargin}>
      <Box
        className={styles.classes.buttonBox}
        bgcolor={type === 'create' ? theme.palette.info.main : theme.palette.info.dark}
      >
        <IconButton className={styles.classes.buttonIcon}>{BUTTON_TYPES[type].icon}</IconButton>
        <Typography fontSize="16px" lineHeight="19px" fontWeight="500">
          {BUTTON_TYPES[type].text}
        </Typography>
      </Box>
    </Link>
  );
};

export default ViewCommonButton;
