import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';

import { Theme, Button, Typography, Box } from '@mui/material';

const useStyles = makeStyles()((theme: Theme) => ({
  default: {
    color: theme.palette.common.white,
    textTransform: 'capitalize',
    borderRadius: '12px',
    width: '100%',
    padding: '10px 16px',
    minHeight: '48px',
  },
  buttonDisabled: {
    background: 'rgba(255, 255, 255, 0.08)',
    border: 'none',
    color: theme.palette.text.disabled,

    '&:hover': {
      background: 'rgba(255, 255, 255, 0.08)',
      border: 'none',
      color: theme.palette.text.disabled,
      cursor: 'default',
    },
    '&.Mui-disabled': {
      color: theme.palette.text.disabled,
      background: 'rgba(255, 255, 255, 0.08)',
    },
  },
  buttonActive: {
    background: theme.palette.info.dark,
    border: `1px solid ${theme.palette.info.dark}`,
    color: theme.palette.common.white,

    '&:hover': {
      background: theme.palette.info.dark,
      border: `1px solid ${theme.palette.info.dark}`,
      color: theme.palette.common.white,
    },
  },
}));

interface FormCancelButtonProps {
  onClick: () => void;
  buttonText?: string;
  disabled?: boolean;
}

const FormCancelButton = ({ onClick, disabled, buttonText }: FormCancelButtonProps) => {
  const styles = useStyles();

  return (
    <Button
      disableRipple
      className={clsx(
        styles.classes.default,
        disabled ? styles.classes.buttonDisabled : styles.classes.buttonActive,
      )}
      variant="outlined"
      onClick={onClick}
      disabled={disabled}
    >
      <Box display="flex" alignItems="center" gap="8px">
        <Typography fontFamily="Inter" fontWeight="500" fontSize="16px">
          {buttonText || 'Cancel'}
        </Typography>
      </Box>
    </Button>
  );
};

export default FormCancelButton;
