import { makeStyles } from 'tss-react/mui';

import { Chip, Theme, useTheme } from '@mui/material';

import { DATA_FILE_STATUSES } from './types';

const useStyles = makeStyles<{ chipColor?: string }>()((theme: Theme, { chipColor }) => ({
  statusPillBar: {
    background: `${chipColor}14`,
    border: `1px solid ${chipColor}`,
    fontSize: '14px',
    padding: 0,
    borderRadius: '12px',
    height: '25px',

    '& .MuiChip-label': {
      fontFamily: 'Inter',
      fontWeight: '500',
      color: chipColor,
      textTransform: 'capitalize',
      padding: '0px 12px 0px 12px',
    },
  },
}));

interface SystemAccountStatusChipProps {
  status: string;
}

const DataFileStatusChip = ({ status }: SystemAccountStatusChipProps) => {
  const theme = useTheme();

  const DataFileStatusesChipColor = {
    Processing: 'rgba(37, 157, 168, 0.72)',
    Error: theme.palette.error.main,
    Queued: theme.palette.warning.main,
    Completed: theme.palette.success.main,
    Pending: theme.palette.warning.main,
  };

  const currentChipColor = DataFileStatusesChipColor[status as keyof typeof DATA_FILE_STATUSES];

  const styles = useStyles({ chipColor: currentChipColor });

  return (
    <Chip
      label={DATA_FILE_STATUSES[status as keyof typeof DATA_FILE_STATUSES]}
      className={styles.classes.statusPillBar}
    />
  );
};

export default DataFileStatusChip;
