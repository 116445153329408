import { useEffect, useState } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';

import { Box } from '@mui/material';

import CasesTableDataRow from './CasesTableDataRow';

import { getCasesList } from '../../api/casesApi/casesApi';
import TableContainer from '../../components/Table/TableContainer';
import Loader from '../../components/Loader';
import { CASES_LIST_TABLE_LABELS } from '../../constants/tableLabels';
import usePagination from '../../hooks/usePagination';
import { ActiveSortField, CaseShortData, FilterTypes } from '../../types';
import NoDataText from '../../components/NoDataText';
import TableFilters from '../../components/TableFilters/TableFilters';
import TableActions from '../../components/Table/TableActions';
import { downloadCsv } from '../../helpers/downloadCsv';
import { CASES_STATUS_FILTER_OPTIONS } from '../../components/TableFilters/constants';
import useSnackBar from '../../hooks/useSnackBar';
import { handleSortTableData } from '../../helpers/handleSortTableData';

const CasesView = () => {
  const params = useParams();
  const location = useLocation();

  const firmId = localStorage.getItem('firmId');

  const { pageNumber, rowsPerPage, setTotalCount } = usePagination();
  const { setAlert } = useSnackBar();

  const [casesList, setCasesList] = useState<CaseShortData[]>([]);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [showFilters, setShowFilters] = useState<boolean>(true);

  const [filters, setFilters] = useState<FilterTypes>({
    firmId: firmId || '',
    clientId: '',
    defendantId: '',
    caseTypeId: '',
  });

  const [match, setMatch] = useState<string>('');
  const [activeSortField, setActiveSortField] = useState<ActiveSortField | null>(null);
  const sortableFields = {
    name: {
      order: '',
      fieldName: 'name',
    },
    createdAt: {
      order: '',
      fieldName: 'createdAt',
    },
    filedAt: {
      order: '',
      fieldName: 'filedAt',
    },
    sol: {
      order: '',
      fieldName: 'sol',
    },
  };

  const hasParams = !!Object.entries(params).length;

  useEffect(() => {
    getCasesList({
      page: pageNumber,
      size: rowsPerPage,
      count: true,
      match: match,
      filters: { ...filters, ...(hasParams && params) },
      activeSortField,
    }).then((res) => {
      if (res.data.resultSet) {
        setTotalCount && setTotalCount(res.data.totalCount || 0);
        setCasesList(res.data.resultSet);
        setIsDataLoading(false);
      }
    });
  }, [pageNumber, rowsPerPage, filters, match, activeSortField]);

  const exportList = () => {
    downloadCsv('/v5/cases/export', 'POST', 'Cases_List', {
      page: pageNumber,
      size: rowsPerPage,
      count: true,
      match: match,
      filters: { ...filters, ...(hasParams && params) },
    }).then((res) => {
      if (res?.data) {
        setAlert({
          message: 'Cases exported successfully.',
          type: 'success',
        });
      }
    });
  };

  return (
    <>
      {isDataLoading && !casesList.length ? (
        <Box position="absolute" top="50%" left="50%">
          <Loader colorType="warning" />
        </Box>
      ) : (
        <>
          <TableActions
            hasParams={hasParams}
            createLink="/cases/create-case"
            exportList={exportList}
            setMatch={setMatch}
            match={match}
            showFilters={showFilters}
            setShowFilters={setShowFilters}
          />

          {showFilters && (
            <TableFilters
              filters={filters}
              setFilters={setFilters}
              setMatch={setMatch}
              timeFilter={'Created'}
              statusFilter={CASES_STATUS_FILTER_OPTIONS}
              client
              defendant={!location.pathname.includes('/defendants')}
              match={match}
              caseType
            />
          )}

          {!isDataLoading && casesList.length === 0 ? (
            <NoDataText />
          ) : (
            <TableContainer
              headerLabels={CASES_LIST_TABLE_LABELS}
              sortableFields={sortableFields}
              activeSortField={activeSortField}
              setActiveSortField={setActiveSortField}
              handleSortTableData={handleSortTableData}
            >
              <CasesTableDataRow tableData={casesList} />
            </TableContainer>
          )}
          <Outlet />
        </>
      )}
    </>
  );
};

export default CasesView;
