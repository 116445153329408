import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import ClearIcon from '@mui/icons-material/Clear';

import { Box, Button, IconButton } from '@mui/material';

import { DATE_FILTER_VALUES, FILTER_DATE_OPTIONS } from './constants';
import { useStyles } from './styles';

import FormAutocompleteField from '../formFields/FormAutocompleteField';
// import FormToggleField from '../formFields/FormToggleField';
import FormSelectField from '../formFields/FormSelectField';
import { useIsFirstRender } from '../../hooks/useIsFirstRender';
import { FilterTypes, SelectOption } from '../../types';
import {
  handleGetCaseTypes,
  handleGetDefendants,
  handleGetPlaintiffs,
  mapCaseTypesQueryResults,
  mapDefendantsQueryResults,
  mapPlaintiffsQueryResults,
} from '../../views/CasesView/helpers';
import useWindowWidth from '../../hooks/useWindowWidth';
import usePagination from '../../hooks/usePagination';
import FormDateField from '../formFields/FormDateField';
import moment from 'moment';

interface ITableFilters {
  filters: FilterTypes;
  setFilters: React.Dispatch<React.SetStateAction<FilterTypes>>;
  setMatch?: (arg: string) => void;
  searchDebounce?: number;
  timeFilter?: string;
  typeFilter?: boolean;
  statusFilter?: SelectOption[];
  client?: boolean;
  defendant?: boolean;
  rolesFilter?: SelectOption[];
  match?: string;
  caseType?: boolean;
  dateRange?: boolean;
  transactionsStatusFilter?: boolean;
  typeFilterOptions?: SelectOption[];
  isStatusArray?: boolean;
  isPlaintiff?: boolean;
}

const TableFilters = ({
  filters,
  setFilters,
  timeFilter,
  typeFilter,
  statusFilter,
  client,
  defendant,
  rolesFilter,
  setMatch,
  match,
  caseType,
  dateRange,
  typeFilterOptions,
  isStatusArray,
  isPlaintiff,
}: ITableFilters) => {
  const styles = useStyles();
  const isFirstRender = useIsFirstRender();
  const windowWidth = useWindowWidth();
  const { setPageNumber } = usePagination();

  const [, setDateFilter] = useState<string>('');

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      searchQuery: '',
      isArchived: false,
      clientId: { id: '', label: '' },
      defendantId: { id: '', label: '' },
      type: '',
      dateFilter: '',
      status: '',
      role: '',
      caseTypeId: { id: '', label: '' },
      state: '',
      from: '',
      to: '',
      statuses: [],
      plaintiffId: { id: '', label: '' },
    },
  });

  const {
    watch,
    reset,
    formState: { isDirty },
  } = formMethods;

  const clientFieldValue = watch('clientId');
  const plaintiffFieldValue = watch('plaintiffId');
  const defendantFieldValue = watch('defendantId');
  const roleFieldValue = watch('role');
  const caseTypeFieldValue = watch('caseTypeId');
  const dateFromFieldValue = watch('from');
  const dateToFieldValue = watch('to');

  useEffect(() => {
    if (isFirstRender) return;

    const newState = {
      clientId: clientFieldValue?.id || '',
      defendantId: defendantFieldValue?.id || '',
      role: roleFieldValue || '',
      caseTypeId: caseTypeFieldValue?.id || '',
      from: dateFromFieldValue || '',
      to: dateToFieldValue || '',
      plaintiffId: plaintiffFieldValue?.id || '',
    };

    setFilters((prevState) => {
      setPageNumber && setPageNumber(1);
      if (JSON.stringify(prevState) !== JSON.stringify(newState)) {
        return {
          ...prevState,
          ...newState,
        };
      } else {
        return prevState;
      }
    });
  }, [
    clientFieldValue,
    defendantFieldValue,
    roleFieldValue,
    caseTypeFieldValue,
    dateFromFieldValue,
    dateToFieldValue,
    plaintiffFieldValue,
  ]);

  const customHandleDateChange = (value: string) => {
    setPageNumber && setPageNumber(1);
    setDateFilter(value);

    const fromDate = new Date(DATE_FILTER_VALUES[value as keyof typeof DATE_FILTER_VALUES].from);
    const toDate = new Date(DATE_FILTER_VALUES[value as keyof typeof DATE_FILTER_VALUES].to);

    setFilters((prevState) => ({
      ...prevState,
      date: { from: fromDate, to: toDate },
    }));
  };

  const handleFiltersGroupClick = (value: string, name: string) => {
    setPageNumber && setPageNumber(1);
    setFilters((prevState) => ({
      ...prevState,
      [name]: isStatusArray ? [value] : value,
    }));
  };

  const handleResetFilters = () => {
    delete filters.date;
    delete filters.type;
    delete filters.clientId;
    delete filters.status;
    delete filters.role;
    delete filters.caseTypeId;
    delete filters.state;
    delete filters.statuses;
    delete filters.plaintiffId;

    setMatch && setMatch('');
    reset();
    setDateFilter('');
  };

  return (
    <FormProvider {...formMethods}>
      <form className={styles.classes.filtersForm}>
        <Box className={styles.classes.filtersContainer}>
          {/* <Box minWidth="175px" ml="5px">
            <FormToggleField name="isArchived" label="Show Archived" disabled />
          </Box> */}

          {dateRange ? (
            <Box display="flex" gap="10px">
              <FormDateField
                label="From"
                name="from"
                maxDate={dateToFieldValue ? moment(dateToFieldValue) : moment()}
                maxWidth="180px"
                readonly
              />
              <FormDateField
                label="To"
                name="to"
                maxDate={moment()}
                minDate={moment(dateFromFieldValue)}
                maxWidth="180px"
                readonly
              />
            </Box>
          ) : null}

          {client ? (
            <FormAutocompleteField
              name={isPlaintiff ? 'plaintiffId' : 'clientId'}
              label="Client"
              optionsQuery={handleGetPlaintiffs}
              mapQueryResults={mapPlaintiffsQueryResults}
              filters={{
                firmId: filters.firmId,
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              minWidth="180px"
            />
          ) : null}

          {defendant ? (
            <FormAutocompleteField
              name="defendantId"
              label="Defendant"
              optionsQuery={handleGetDefendants}
              mapQueryResults={mapDefendantsQueryResults}
              filters={{
                firmId: filters.firmId,
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              minWidth="180px"
            />
          ) : null}

          {caseType ? (
            <FormAutocompleteField
              name="caseTypeId"
              label="Case Type"
              optionsQuery={handleGetCaseTypes}
              mapQueryResults={mapCaseTypesQueryResults}
              filters={{
                firmId: filters.firmId,
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              minWidth="180px"
            />
          ) : null}

          {timeFilter ? (
            <FormSelectField
              name="dateFilter"
              label={timeFilter}
              options={FILTER_DATE_OPTIONS}
              handleChange={customHandleDateChange}
              minWidth="180px"
            />
          ) : null}

          {rolesFilter ? (
            <FormSelectField
              label="Roles"
              options={rolesFilter}
              name="role"
              handleChange={handleFiltersGroupClick}
              minWidth="180px"
            />
          ) : null}

          {typeFilter ? (
            <FormSelectField
              name="type"
              label="Type"
              options={typeFilterOptions as SelectOption[]}
              minWidth="180px"
              handleChange={handleFiltersGroupClick}
            />
          ) : null}

          {statusFilter ? (
            <FormSelectField
              label="Status"
              options={statusFilter}
              name={isStatusArray ? 'statuses' : 'status'}
              minWidth="180px"
              handleChange={handleFiltersGroupClick}
            />
          ) : null}

          {windowWidth <= 550 ? (
            <Button
              disableRipple
              className={styles.classes.clearFiltersButton}
              disabled={!(isDirty || match?.length)}
              onClick={handleResetFilters}
            >
              Clear filters
            </Button>
          ) : (
            <IconButton
              disableRipple
              className={styles.classes.clearIconContainer}
              disabled={!(isDirty || match?.length)}
              onClick={handleResetFilters}
            >
              <ClearIcon />
            </IconButton>
          )}
        </Box>
      </form>
    </FormProvider>
  );
};

export default TableFilters;
