import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { Box } from '@mui/material';

import DataFileUploadPopup from './DataFileUploadPopup';
import DataFileDownloadTemplatePopup from './DataFileDownloadTemplatePopup';
import FileManagerTableDataRow from './FileManagerTableDataRow';
import { FilesData } from './types';

import { DATA_FILE_MANAGER_TABLE_LABELS } from '../../constants/tableLabels';
import ViewCommonButton from '../../components/buttons/ViewCommonButton';
import Loader from '../../components/Loader';
import NoDataText from '../../components/NoDataText';
import TableActions from '../../components/Table/TableActions';
import TableContainer from '../../components/Table/TableContainer';
import TableFilters from '../../components/TableFilters/TableFilters';
import {
  DATA_FILE_STATUS_FILTER_OPTIONS,
  DATA_FILE_TYPE_FILTER_OPTIONS,
} from '../../components/TableFilters/constants';
import { handleSortTableData } from '../../helpers/handleSortTableData';
import { ActiveSortField, FilterTypes } from '../../types';

const FAKE_FILES_DATA_ARRAY = [
  {
    uploadedAt: '12/02/2024 12:19 PM',
    fileName: 'cases.csv',
    fileType: 'Cases File',
    uploadedBy: 'Medica@gmail.com',
    status: 'Processing',
  },
  {
    uploadedAt: '12/02/2024 12:19 PM',
    fileName: 'cases.csv',
    fileType: 'Cases File',
    uploadedBy: 'Medica@gmail.com',
    status: 'Error',
  },
  {
    uploadedAt: '12/02/2024 12:19 PM',
    fileName: 'cases.csv',
    fileType: 'Cases File',
    uploadedBy: 'Medica@gmail.com',
    status: 'Queued',
  },
  {
    uploadedAt: '12/02/2024 12:19 PM',
    fileName: 'cases.csv',
    fileType: 'Cases File',
    uploadedBy: 'Medica@gmail.com',
    status: 'Completed',
  },
  {
    uploadedAt: '12/02/2024 12:19 PM',
    fileName: 'cases.csv',
    fileType: 'Cases File',
    uploadedBy: 'Medica@gmail.com',
    status: 'Pending',
  },
];

const FileManagerView = () => {
  const params = useParams();
  const firmId = localStorage.getItem('firmId');
  const hasParams = !!Object.entries(params).length;

  const [isUploadPopupOpen, setIsUploadPopupOpen] = useState<boolean>(false);
  const [isDonwloadTemplatePopupOpen, setIsDonwloadTemplatePopupOpen] = useState<boolean>(false);
  const [filesList] = useState<FilesData[]>(FAKE_FILES_DATA_ARRAY);
  const [isDataLoading] = useState(false);
  const [showFilters, setShowFilters] = useState<boolean>(true);
  const [match, setMatch] = useState<string>('');
  const [activeSortField, setActiveSortField] = useState<ActiveSortField | null>(null);
  const [filters, setFilters] = useState<FilterTypes>({
    firmId: firmId || '',
    clientId: '',
    defendantId: '',
  });
  const sortableFields = {};

  return (
    <>
      {isDataLoading && !filesList.length ? (
        <Box position="absolute" top="50%" left="50%">
          <Loader colorType="warning" />
        </Box>
      ) : (
        <>
          <Box display="flex" justifyContent="space-between">
            <TableActions
              hasParams={hasParams}
              match={match}
              showFilters={showFilters}
              setShowFilters={setShowFilters}
              setMatch={setMatch}
            />
            <Box display="flex" gap="16px">
              <ViewCommonButton type="upload" onClick={() => setIsUploadPopupOpen(true)} />
              <ViewCommonButton
                type="downloadTemplate"
                onClick={() => setIsDonwloadTemplatePopupOpen(true)}
              />
            </Box>
          </Box>

          {showFilters && (
            <TableFilters
              filters={filters}
              setFilters={setFilters}
              setMatch={setMatch}
              match={match}
              statusFilter={DATA_FILE_STATUS_FILTER_OPTIONS}
              typeFilter
              typeFilterOptions={DATA_FILE_TYPE_FILTER_OPTIONS}
              timeFilter="Uploaded"
            />
          )}

          {!isDataLoading && filesList.length === 0 ? (
            <NoDataText />
          ) : (
            <TableContainer
              headerLabels={DATA_FILE_MANAGER_TABLE_LABELS}
              sortableFields={sortableFields}
              activeSortField={activeSortField}
              setActiveSortField={setActiveSortField}
              handleSortTableData={handleSortTableData}
            >
              <FileManagerTableDataRow tableData={filesList} />
            </TableContainer>
          )}

          <DataFileUploadPopup
            isOpen={isUploadPopupOpen}
            onClose={() => setIsUploadPopupOpen(false)}
          />
          <DataFileDownloadTemplatePopup
            isOpen={isDonwloadTemplatePopupOpen}
            onClose={() => setIsDonwloadTemplatePopupOpen(false)}
          />
        </>
      )}
    </>
  );
};

export default FileManagerView;
