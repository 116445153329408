import { ChangeEvent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';

import { TextField, Theme } from '@mui/material';

const useStyles = makeStyles()((theme: Theme) => ({
  defaultImageField: {
    visibility: 'hidden',
    width: 0,
    height: 0,
    input: {
      visibility: 'hidden',
    },
    '& .MuiFormHelperText-root': {
      visibility: 'visible',
      marginLeft: '8px',
      position: 'absolute',
      bottom: '-20px',
    },
  },
  fieldLabel: {
    boxSizing: 'border-box',
    display: 'inline-flex',
    maxWidth: '150px',
    width: '100%',
    padding: '9px',
    backgroundColor: theme.palette.info.dark,
    borderRadius: '12px',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '120%',
    color: theme.palette.common.white,

    svg: {
      fontSize: '18px',
    },

    '&:hover': {
      cursor: 'pointer',
    },
  },
  fieldLabelDisabled: {
    color: theme.palette.text.disabled,
    background: 'rgba(255, 255, 255, 0.08)',

    '&:hover': {
      cursor: 'default',
    },
  },
}));

interface FormImageFieldProps {
  name: string;
  label: string;
  disabled?: boolean;
  customHandler?: (newValue: string) => void;
}

const FormImageField = ({ name, label, disabled, customHandler }: FormImageFieldProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { classes } = useStyles();

  const helperText = errors[name] && errors[name]?.message;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <label className={clsx(classes.fieldLabel, disabled && classes.fieldLabelDisabled)}>
          <TextField
            className={classes.defaultImageField}
            name={name}
            type="file"
            value={value?.fileName}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              if (e.target.files?.length) {
                onChange(e.target.files[0]);
                customHandler && customHandler(e.target.files[0]?.name);
              }
            }}
            helperText={helperText as string}
            error={!!errors[name]}
            inputProps={{
              accept: '.csv',
            }}
            disabled={disabled}
          />
          {label}
        </label>
      )}
    />
  );
};

export default FormImageField;
