import { TableCell, TableRow } from '@mui/material';

import DataFileStatusChip from './DataFileStatusChip';
import { FilesData } from './types';

interface FileManagerTableDataRowProps {
  tableData: FilesData[];
}

const FileManagerTableDataRow = ({ tableData }: FileManagerTableDataRowProps) => {
  const formattedClientsList =
    tableData &&
    tableData.map((file) => ({
      uploadedAt: file.uploadedAt,
      fileName: file.fileType,
      fileType: file.fileName,
      uploadedBy: file.uploadedBy,
      status: <DataFileStatusChip status={file.status} />,
    }));

  return (
    <>
      {formattedClientsList.map((tableItem, idx) => (
        <TableRow key={idx}>
          {Object.values(tableItem).map((rowField, idx) => (
            <TableCell key={idx}>{rowField}</TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

export default FileManagerTableDataRow;
