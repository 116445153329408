export interface FilesData {
  uploadedAt: string;
  fileName: string;
  fileType: string;
  uploadedBy: string;
  status: string;
}

export enum DATA_FILE_STATUSES {
  Processing = 'Processing',
  Error = 'Error',
  Queued = 'Queued',
  Completed = 'Completed',
  Pending = 'Pending',
}
