import { FormProvider, useForm } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Theme,
} from '@mui/material';

import { UPLOAD_FILE_TYPE_OPTIONS } from '../../constants/constants';
import FormSubmitButton from '../../components/buttons/FormSubmitButton';
import FormSelectField from '../../components/formFields/FormSelectField';
import FormCancelButton from '../../components/buttons/FormCancelButton';
import { DATA_FILE_DOWNLOAD_TEMPLATE_FORM_DEFAULT_VALUES } from '../../helpers/formSchemas/formDefaultValues';
import { dataFileDownloadTemplateFormSchema } from '../../helpers/formSchemas/formSchemas';

const useStyles = makeStyles()((theme: Theme) => ({
  popupContainer: {
    '& .MuiDialog-paper': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
      padding: '32px',
      maxWidth: '670px',
      width: '100%',
      boxSizing: 'border-box',
      borderRadius: '16px',
    },

    '& .MuiDialogContentText-root': {
      color: theme.palette.common.white,
    },
  },
  popupTitle: {
    fontSize: '28px',
    lineHeight: '120%',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontStyle: 'normal',
    padding: 0,
    marginBottom: '8px',
  },
  popupContentContainer: {
    padding: 0,
    paddingTop: '10px',
  },
  popupSecondaryText: {
    fontSize: '16px',
    lineHeight: '120%',
    fontFamily: 'Inter',
    fontWeight: 500,
    fontStyle: 'normal',
    color: '#C3C4C8',
    marginBottom: '22px',
  },
  deleteIcon: {
    padding: 0,
    svg: {
      color: theme.palette.error.main,
      marginTop: '1px',
    },
  },
  popupActions: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '416px',
    width: '100%',
    margin: '0 auto',
    gap: '16px',
    padding: 0,
    marginTop: '72px',
  },
}));

interface DataFileDownloadTemplatePopupProps {
  isOpen: boolean;
  onClose: () => void;
}

const DataFileDownloadTemplatePopup = ({ isOpen, onClose }: DataFileDownloadTemplatePopupProps) => {
  const { classes } = useStyles();

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(dataFileDownloadTemplateFormSchema),
    defaultValues: DATA_FILE_DOWNLOAD_TEMPLATE_FORM_DEFAULT_VALUES,
  });

  const {
    reset,
    formState: { isValid },
  } = formMethods;

  const handleClosePopup = () => {
    reset();
    onClose();
  };

  return (
    <Dialog open={isOpen} className={classes.popupContainer} onClose={handleClosePopup}>
      <Box display="flex" justifyContent="flex-end" height="24px">
        <IconButton
          disableRipple
          onClick={handleClosePopup}
          sx={{
            color: 'white',
            width: '24px',
            height: '24px',
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogTitle className={classes.popupTitle}>Download Template</DialogTitle>
      <DialogContent className={classes.popupContentContainer}>
        <FormProvider {...formMethods}>
          <Box display="flex" padding="40px 32px" gap="32px" justifyContent="center">
            <FormSelectField
              name="fileType"
              label="File Type"
              options={UPLOAD_FILE_TYPE_OPTIONS}
              width="100%"
              maxWidth="416px"
            />
          </Box>
        </FormProvider>
      </DialogContent>
      <DialogActions className={classes.popupActions}>
        <FormSubmitButton disabled={!isValid} buttonText="Submit" onClick={handleClosePopup} />

        <FormCancelButton onClick={handleClosePopup} />
      </DialogActions>
    </Dialog>
  );
};

export default DataFileDownloadTemplatePopup;
