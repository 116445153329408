import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';

import { Box, useTheme, Typography, Theme, IconButton, ClickAwayListener } from '@mui/material';

import PeopleIcon from '@mui/icons-material/People';
import GavelIcon from '@mui/icons-material/Gavel';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ListIcon from '@mui/icons-material/List';
import ThreePIcon from '@mui/icons-material/ThreeP';
// import BarChartIcon from '@mui/icons-material/BarChart';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import ClearIcon from '@mui/icons-material/Clear';
import CategoryIcon from '@mui/icons-material/Category';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import useAuth from '../hooks/useAuth';
import { ReactComponent as LogoIcon } from '../logo.svg';
import useWindowWidth from '../hooks/useWindowWidth';

type sidebarLinks = {
  linkName: string;
  path: string;
  icon: JSX.Element;
  separator?: boolean;
};

const menuItems: sidebarLinks[] = [
  {
    linkName: 'Cases',
    path: 'cases',
    icon: <GavelIcon />,
  },
  {
    linkName: 'Requests',
    path: 'requests',
    icon: <AssignmentTurnedInIcon />,
  },
  {
    linkName: 'Payments',
    path: 'payments',
    icon: <ReceiptIcon />,
    separator: true,
  },
  {
    linkName: 'Clients',
    path: 'clients',
    icon: <FolderSharedIcon />,
  },
  {
    linkName: 'Defendants',
    path: 'defendants',
    icon: <FingerprintIcon />,
    separator: true,
  },
  {
    linkName: 'Case Types',
    path: 'case-types',
    icon: <CategoryIcon />,
  },
  {
    linkName: 'Questionnaires',
    path: 'questionnaires',
    icon: <DynamicFormIcon />,
  },
  {
    linkName: 'Snippets',
    path: 'snippets',
    icon: <TextSnippetIcon />,
    separator: true,
  },
  {
    linkName: 'Data File Manager',
    path: 'file-manager',
    icon: <ListIcon />,
  },
  {
    linkName: 'Banking',
    path: 'banking',
    icon: <AttachMoneyIcon />,
    separator: true,
  },
  {
    linkName: 'Communications',
    path: 'communications',
    icon: <ThreePIcon />,
  },
  {
    linkName: 'Users',
    path: 'users',
    icon: <PeopleIcon />,
  },
  // {
  //   linkName: 'Financial',
  //   path: 'financial',
  //   icon: <BarChartIcon />,
  // },
];

const menuBottomItems = [
  {
    linkName: 'Manage Account',
    path: 'manage-account',
    icon: <ManageAccountsIcon />,
  },
];

const useStyles = makeStyles<{ isMenuOpen?: boolean; isSidebarCollapsed?: boolean }>()(
  (theme: Theme, { isMenuOpen, isSidebarCollapsed }) => ({
    sideBarExpanded: {
      maxWidth: '201px',
      width: '100%',
    },
    sideBarCollapsed: {
      maxWidth: '48px',
      width: '100%',
    },
    activeLink: {
      color: theme.palette.common.white,
      display: isSidebarCollapsed ? 'none' : 'block',
      fontSize: '16px',
      fontWeight: 500,
      fontFamily: 'Inter',
    },
    defaultLink: {
      color: theme.palette.text.secondary,
      display: isSidebarCollapsed ? 'none' : 'block',
      fontSize: '16px',
      fontWeight: 500,
      fontFamily: 'Inter',
    },
    iconWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '24px',
      height: '24px',
      background: theme.palette.text.disabled,
      borderRadius: '100%',
      '& svg': {
        fontSize: '16px',
        color: theme.palette.primary.light,
      },
    },
    activeItemIconWrapper: {
      background: theme.palette.common.white,
    },
    menuItem: {
      padding: '12px',
      borderRadius: '12px',
      transition: 'padding 0.5s ease',

      '& :hover': {
        cursor: 'pointer',
      },
    },
    activeMenuItem: {
      background: 'rgba(255,255,255,0.19)',
    },
    linkHover: {
      '& :hover': {
        backgroundColor: 'rgba(255,255,255,0.19)',
        cursor: 'pointer',
        '& div': {
          background: theme.palette.common.white,
        },
        '& p': {
          background: 'transparent',
          color: theme.palette.common.white,
        },
        '& svg': {
          background: 'transparent',
        },
      },
    },
    linkHoverSeparator: {
      borderBottom: '1px solid rgba(118, 120, 133, 0.5)',
      paddingBottom: 8,
      marginBottom: 4,
    },
    sideBarWrapper: {
      display: 'flex',
      flexDirection: 'column',
      padding: '24px 8px',
      backgroundColor: theme.palette.primary.light,
      borderRight: `1px solid ${theme.palette.secondary.dark}`,
      overflowY: 'auto',

      '@media (max-width: 550px)': {
        padding: 0,
        position: 'fixed',
        overflow: 'auto',
        width: '100%',
        zIndex: 2,
        ...(isMenuOpen && { paddingBottom: '16px', height: '100dvh' }),
      },
    },
    sideBarMainMenuGroup: {
      display: 'flex',
      flexDirection: 'column',
      gap: '4px',
      marginTop: '56px',

      '@media (max-width: 550px)': {
        padding: '0 16px',
        display: isMenuOpen ? 'flex' : 'none',
        marginTop: 0,
      },
    },
    sideBarBottomMenuGroup: {
      display: 'flex',
      flexDirection: 'column',
      gap: '4px',
      marginTop: 'auto',

      '@media (max-width: 550px)': {
        padding: '0 16px',
        display: 'none',
        marginTop: 'auto',
        ...(isMenuOpen && { paddingBottom: '16px', display: 'flex' }),
      },
    },
    logoIconContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginLeft: '8px',
      alignItems: 'center',

      '@media (max-width: 550px)': {
        padding: '16px',
        marginLeft: 0,
      },
    },
    burgerMenuIcon: {
      display: 'none',
      svg: {
        color: theme.palette.common.white,
      },

      '@media (max-width: 550px)': {
        display: 'flex',
      },
    },
  }),
);

interface SidebarProps {
  isSidebarCollapsed: boolean;
}

const Sidebar = ({ isSidebarCollapsed }: SidebarProps) => {
  const theme = useTheme();
  const location = useLocation();
  const windowWidth = useWindowWidth();
  const navigate = useNavigate();
  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState<boolean>(false);

  const styles = useStyles({ isMenuOpen: isBurgerMenuOpen, isSidebarCollapsed });

  const role = localStorage.getItem('role');
  const { onLogOut } = useAuth();

  const baseRouteName = location.pathname.split('/')[1];

  const handleOpenBurgerMenu = () => setIsBurgerMenuOpen(!isBurgerMenuOpen);

  const onLinkClick = (path: string) => {
    setIsBurgerMenuOpen(false);
    navigate(path);
  };

  useEffect(() => {
    windowWidth > 550 && setIsBurgerMenuOpen(false);
  }, [windowWidth]);

  return (
    <ClickAwayListener onClickAway={() => setIsBurgerMenuOpen(false)}>
      <Box
        className={clsx(
          styles.classes.sideBarWrapper,
          windowWidth > 550
            ? isSidebarCollapsed
              ? styles.classes.sideBarCollapsed
              : styles.classes.sideBarExpanded
            : 'none',
        )}
      >
        <Box className={styles.classes.logoIconContainer}>
          <Link to="/">
            <LogoIcon />
          </Link>

          <IconButton
            className={styles.classes.burgerMenuIcon}
            onClick={handleOpenBurgerMenu}
            disableRipple
          >
            {isBurgerMenuOpen ? <ClearIcon /> : <MenuIcon />}
          </IconButton>
        </Box>

        <Box className={styles.classes.sideBarMainMenuGroup}>
          {menuItems.map((item) => {
            if (role !== 'FirmAdmins' && item.path === 'users') return null;

            return (
              <Box key={item.path}>
                <Box
                  className={clsx(
                    styles.classes.linkHover,
                    item.separator && styles.classes.linkHoverSeparator,
                  )}
                  onClick={() => onLinkClick(item.path)}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    gap="8px"
                    color={theme.palette.primary.light}
                    className={clsx(
                      styles.classes.menuItem,
                      baseRouteName === item.path && styles.classes.activeMenuItem,
                    )}
                  >
                    <Box
                      className={clsx(
                        styles.classes.iconWrapper,
                        baseRouteName === item.path && styles.classes.activeItemIconWrapper,
                      )}
                    >
                      {item.icon}
                    </Box>
                    <Typography
                      className={clsx(
                        baseRouteName === item.path
                          ? styles.classes.activeLink
                          : styles.classes.defaultLink,
                      )}
                    >
                      {item.linkName}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            );
          })}
        </Box>

        <Box className={styles.classes.sideBarBottomMenuGroup}>
          {menuBottomItems.map((item, idx) => (
            <Box key={idx}>
              <Box onClick={() => onLinkClick(item.path)} className={styles.classes.linkHover}>
                <Box
                  display="flex"
                  alignItems="center"
                  gap="8px"
                  color={theme.palette.primary.light}
                  className={clsx(
                    styles.classes.menuItem,
                    baseRouteName === item.path && styles.classes.activeMenuItem,
                  )}
                >
                  <Box
                    className={clsx(
                      styles.classes.iconWrapper,
                      baseRouteName === item.path && styles.classes.activeItemIconWrapper,
                    )}
                  >
                    {item.icon}
                  </Box>
                  <Typography
                    className={clsx(
                      baseRouteName === item.path
                        ? styles.classes.activeLink
                        : styles.classes.defaultLink,
                    )}
                  >
                    {item.linkName}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ))}

          <Box className={clsx(styles.classes.linkHover)}>
            <Box
              display="flex"
              alignItems="center"
              gap="8px"
              color={theme.palette.primary.light}
              className={clsx(styles.classes.menuItem)}
              onClick={onLogOut}
            >
              <Box className={styles.classes.iconWrapper}>
                <LogoutIcon />
              </Box>
              <Typography className={clsx(styles.classes.defaultLink)}>Sign Out</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </ClickAwayListener>
  );
};

export default Sidebar;
